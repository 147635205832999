import './styles/settlementModal.css';

interface ComponentProps {
  amount?: number;
  accountName?: string;
  accountNumber?: string;
  bank?: string;
  type?: string;
  status?: string;
  date?: Date;
}

const SettlementModal: React.FC<ComponentProps> = ({
  amount,
  accountName,
  accountNumber,
  bank,
  type,
  status,
  date,
}) => {
  return (
    <div className="settlement_modal_general_wrapper">
      <p className="settlement_details_modal_header">Settlement Details</p>
      <div className="settlement_details_modal_contents_box">
        <div className="settlement_details_modal_contents_content_holder">
          <p className="settlement_details_modal_contents_content_head">Amount</p>
          <p className="settlement_details_modal_contents_content_details">{amount}</p>
        </div>
        <div  className="settlement_details_modal_contents_content_holder">
          <p className="settlement_details_modal_contents_content_head">Account Name</p>
          <p  className="settlement_details_modal_contents_content_details">{accountName}</p>
        </div>
        <div  className="settlement_details_modal_contents_content_holder">
          <p className="settlement_details_modal_contents_content_head">Account No.</p>
          <p>{accountNumber}</p>
        </div>
        <div  className="settlement_details_modal_contents_content_holder">
          <p className="settlement_details_modal_contents_content_head">Bank</p>
          <p>{bank}</p>
        </div>
        <div  className="settlement_details_modal_contents_content_holder">
          <p className="settlement_details_modal_contents_content_head">Type</p>
          <p className="settlement_details_modal_contents_content_details">{type}</p>
        </div>
        <div  className="settlement_details_modal_contents_content_holder">
          <p className="settlement_details_modal_contents_content_head">Status</p>
          <p className="settlement_details_modal_contents_content_details">{status}</p>
        </div>
        <div  className="settlement_details_modal_contents_content_holder">
          <p className="settlement_details_modal_contents_content_head">Date</p>
          <p className="settlement_details_modal_contents_content_details">{date ? date.toLocaleDateString() : "N/A"}</p>
        </div>
      </div>
    </div>
  );
};

export default SettlementModal;
