export const settlementData = [
  {
    merchant: {
      name: "Igwe Ventures",
      email: "abby@gmail.com",
    },
    bankDetails: {
      bank_name: "Standard Chartered",
      account_number: "1234567890",
      account_name: "Abby Igwe",
    },
    type: "Auto Settlement",
    amount: 270000,
    date: 
      "2024-05-18T14:16:00"
    ,
    status: "1",
  },
  {
    merchant: {
      name: "Kofi Enterprises",
      email: "kofi@gmail.com",
    },
    bankDetails: {
      bank_name: "First Bank",
      account_number: "2345678901",
      account_name: "Kofi Amankwah",
    },
    type: "Manual Settlement",
    amount: 350000,
    date: "2024-05-18T14:16:00",
    status: "0",
  },
  {
    merchant: {
      name: "Obi Mart",
      email: "obi@yahoo.com",
    },
    bankDetails: {
      bank_name: "Access Bank",
      account_number: "3456789012",
      account_name: "Obinna Chukwudi",
    },
    type: "Auto Settlement",
    amount: 150000,
    date: "2024-05-18T14:16:00",
    status: "1",
  },
  {
    merchant: {
      name: "Jane's Boutique",
      email: "jane@gmail.com",
    },
    bankDetails: {
      bank_name: "UBA",
      account_number: "4567890123",
      account_name: "Jane Doe",
    },
    type: "Manual Settlement",
    amount: 80000,
    date: "2024-05-18T14:16:00",
    status: "1",
  },
  {
    merchant: {
      name: "Mega Store",
      email: "mega@gmail.com",
    },
    bankDetails: {
      bank_name: "Zenith Bank",
      account_number: "5678901234",
      account_name: "Mega Holdings",
    },
    type: "Auto Settlement",
    amount: 500000,
    date: "2024-05-18T14:16:00",
    status: "0",
  },
  {
    merchant: {
      name: "Linda's Groceries",
      email: "linda@yahoo.com",
    },
    bankDetails: {
      bank_name: "GTBank",
      account_number: "6789012345",
      account_name: "Linda Okoro",
    },
    type: "Manual Settlement",
    amount: 120000,
    date: "2024-05-18T14:16:00",
    status: "1",
  },
  {
    merchant: {
      name: "Tech Solutions",
      email: "tech@live.com",
    },
    bankDetails: {
      bank_name: "Sterling Bank",
      account_number: "7890123456",
      account_name: "Tech Solutions Ltd",
    },
    type: "Auto Settlement",
    amount: 250000,
    date: "2024-05-18T14:16:00",
    status: "0",
  },
  {
    merchant: {
      name: "Quick Services",
      email: "quick@outlook.com",
    },
    bankDetails: {
      bank_name: "Polaris Bank",
      account_number: "8901234567",
      account_name: "Quick Services",
    },
    type: "Manual Settlement",
    amount: 320000,
    date: "2024-05-18T14:16:00",
    status: "1",
  },
  {
    merchant: {
      name: "Alpha Traders",
      email: "alpha@gmail.com",
    },
    bankDetails: {
      bank_name: "Fidelity Bank",
      account_number: "9012345678",
      account_name: "Alpha Trading Co.",
    },
    type: "Auto Settlement",
    amount: 450000,
    date: "2024-05-18T14:16:00",
    status: "1",
  },
  {
    merchant: {
      name: "Bright Rentals",
      email: "bright@gmail.com",
    },
    bankDetails: {
      bank_name: "Wema Bank",
      account_number: "0123456789",
      account_name: "Bright Rentals Ltd",
    },
    type: "Manual Settlement",
    amount: 230000,
    date: "2024-05-18T14:16:00",
    status: "0",
  },
  // Add more objects up to 20
];
