import React from 'react';

interface MyComponentProps {
	title?: string;
	text_one?: string;
	text_two?: string;
	asTwin?: boolean;
}

const TwoDetailView: React.FC<MyComponentProps> = ({
	title,
	text_one,
	text_two,
	asTwin,
}) => {
	return (
		<div style={{ display: 'flex', flexDirection: 'column', gap: '.4rem' }}>
			<p style={{ fontWeight: '600', fontSize: '1.4rem' }} className="title">
				{title || ''}
			</p>
			{text_one && (
				<div
					style={{ display: 'flex', alignItems: 'center', gap: '0.3rem' }}
					className="text-boxes"
				>
					<p
						style={{ fontSize: '1.2rem' }}
						className="text-one grey-white-color"
					>
						{text_one || ''}
					</p>
					{text_two ? <span className="grey-white-color">{'•'}</span> : ''}
					<p
						style={{ fontSize: '1.2rem' }}
						className="text-two grey-white-color"
					>
						{text_two || ''}
					</p>
				</div>
			)}
		</div>
	);
};

export default TwoDetailView;
