import PageLayout from '../../../../layout/dashboard/tableLayout';
import './styles/settlementIndex.css';
import DashboardLayout from '../../../../layout/dashboard/dashboardLayout';
import { useState } from 'react';

import { useSelector } from 'react-redux';
import RootState from '../../../../redux/types';
import EmptyScreen from '../../../../components/common/emptyScreen';
import SmartFilter from '../../../../components/common/smartFilter';
import Pagination from '../../../../components/common/pagination';
import { settlementData } from './settlementData';
import { FaRegEye } from 'react-icons/fa';
import { icons } from '../../../../assets/icons';
import {
	capitalizeFirstWord,
	downloadCsvFile,
	formatDateHelper,
	formatDateTime,
	formatNumWithComma,
	formatNumWithCommaNairaSymbolTwo,
	formatTypeFunction,
	getActionNamesByPolicyName,
	symbol,
} from '../../../../utils/helper/Helper';
import {
	RavenButton,
	RavenModal,
	RavenTable,
	RavenTableRow,
} from '@ravenpay/raven-bank-ui';
import DateFragmentBox from '../../../../components/common/DateFragmentBox';
import TwoDetailView from '../../../../components/common/TwoDetailView';
import BadgeComponent from '../../../../components/common/badge/BadgeComponent';
import EditFeeCategoryModal from '../fee-details/EditFeeCategoryModal';
import SettlementModal from './settlementModal';
const Settlement = () => {
	const [showExport, setShowExport] = useState<boolean>(false);
	const [showModal, setShowModal] = useState<boolean>(false);
	const { profile } = useSelector((state: RootState) => state.settings);
	const [noContent, setNoContent] = useState(true);
	const [currentPage, setCurrentPage] = useState(1);
	const [singleChild, setSingleChild] = useState<any>({});
	const { all_fees, loading } = useSelector(
		(state: RootState) => state.bankbox
	);
	const settlementDetails = {
    amount: 5000,
    accountName: "John Doe",
    accountNumber: "1234567890",
    bank: "Standard Bank",
    type: "Auto Settlement",
    status: "Successful",
    date: new Date(),
  };

	const editIcon = (
		<svg
			className="img"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M21 18.0002L19.9999 19.0943C19.4695 19.6744 18.7501 20.0002 18.0001 20.0002C17.2501 20.0002 16.5308 19.6744 16.0004 19.0943C15.4692 18.5154 14.75 18.1903 14.0002 18.1903C13.2504 18.1903 12.5311 18.5154 12 19.0943M3 20.0002H4.67454C5.16372 20.0002 5.40832 20.0002 5.63849 19.945C5.84256 19.896 6.03765 19.8152 6.2166 19.7055C6.41843 19.5818 6.59138 19.4089 6.93729 19.063L19.5 6.50023C20.3285 5.6718 20.3285 4.32865 19.5 3.50023C18.6716 2.6718 17.3285 2.6718 16.5 3.50023L3.93726 16.063C3.59136 16.4089 3.4184 16.5818 3.29472 16.7837C3.18506 16.9626 3.10425 17.1577 3.05526 17.3618C3 17.5919 3 17.8365 3 18.3257V20.0002Z"
				stroke="black"
				strokeWidth="1.2"
				strokeLinecap="round"
				strokeLinejoin="round"
				className=""
			/>
		</svg>
	);
	function isDropped(idx: number | string) {
		if (showDrop.id === idx && showDrop.show) return true;
		else return false;
	}
	// function DropMore({ className, idx, chi }: any) {
	// 	return (
	// 		<div className={`more-wrap ${className}`}>
	// 			{/* {chi?.status === 1 && ( */}
	// 			<div
	// 				style={{
	// 					opacity: '1',
	// 					cursor: 'pointer',
	// 				}}
	// 				onClick={() => {
	// 					// console.log(chi);

	// 					setSingleChild(chi);
	// 					// setShowModal((prev) => {
	// 					// 	return { ...prev, view_fee: true };
	// 					// });
	// 				}}
	// 				className="more_item"
	// 			>
	// 				<figure>
	// 					<FaRegEye />
	// 				</figure>
	// 				<p>View Fee</p>
	// 			</div>
	// 			<div
	// 				style={{
	// 					opacity: '1',
	// 					cursor: 'pointer',
	// 				}}
	// 				onClick={() => {
	// 					setSingleChild(chi);
	// 					// setShowModal((prev) => {
	// 					// 	return { ...prev, edit_fee: true };
	// 					// });
	// 				}}
	// 				className="more_item"
	// 			>
	// 				<figure className="img-box">{editIcon}</figure>
	// 				<p>Edit Fee</p>
	// 			</div>
	// 			{/* )} */}
	// 		</div>
	// 	);
	// }
	const [isModalOpen, setIsModalOpen] = useState(false);
	const openModal = () => {
		setIsModalOpen(true);
	};
	const closeModal = () => {
		setIsModalOpen(false);
	};
	const [showDrop, setShowDrop] = useState<{
		show: boolean;
		id: number | string;
	}>({
		show: false,
		id: '',
	});

	// if (
	// 	!getActionNamesByPolicyName(
	// 		profile?.rolePolicies?.policies,
	// 		'settlement'
	// 	)?.includes('settlement')
	// ) {
	// 	return (
	// 		<DashboardLayout>
	// 			<>
	// 				<EmptyScreen
	// 					loading={Object?.keys(profile)?.length < 1 ? true : false}
	// 					title="Permission Denied"
	// 					subTitle="You have no permission to access this page, Please contact admin or support."
	// 				></EmptyScreen>
	// 			</>
	// 		</DashboardLayout>
	// 	);
	// }
	return (
		<>
			<DashboardLayout>
				<PageLayout
					pageSubtitle="View settlement history with merchants"
					pageTitle="Settlement"
					topRightContent={<></>}
				>
					<div className="input_filter_export_general_wrap">
						<SmartFilter
							// hideFilter
							//  hideExport
							searchTitle="Search by category"
							defaultFilterBy="status"

							// filters={[
							// 	{
							// 		label: 'Status',
							// 		filterBy: 'status',
							// 		options: [
							// 			{
							// 				label: 'Pending',
							// 				value: 'pending',
							// 			},
							// 			{
							// 				label: 'Failed',
							// 				value: 'failed',
							// 			},
							// 			{
							// 				label: 'Successful',
							// 				value: 'succesful',
							// 			},
							// 		],
							// 	},
							// ]}
							// onExport={() => {
							// 	setShowModal((prev) => {
							// 		return { ...prev, export: true };
							// 	});
							// }}
							// page="bankbox_transactions"
							// onSearchChange={debounce((e) => {
							// 	dispatch(
							// 		bankboxAPI.getAllFees({
							// 			per_page: 20,
							// 			page: currentPage,
							// 			search: e,
							// 		})
							// 	);
							// }, 500)}
						/>

						<div className="pagination-wrap">
							{' '}
							<Pagination
								className="top-bar__pagination"
								currentPage={currentPage}
								itemsPerPage={all_fees?.pagination?.per_page}
								totalItems={all_fees?.pagination?.total}
								onPageChange={function (page: number): void {
									setCurrentPage(page);
								}}
							/>
						</div>
					</div>

					<div
						// onClick={() => {
						// 	console.log(all_fees);
						// }}
						className="fee-detail-dashboard-content-index-wrap"
					>
						{/* table content start */}
						<div className="transaction__table non-mobile">
							<RavenTable
								action={false}
								className="table__main"
								headerList={[
									'MERCHANT',
									'BANK DETAILS',
									'TYPE',
									'AMOUNT',
									'DATE',
									'STATUS',
								]}
							>
								{settlementData?.map((chi: any, idx: any) => (
									<RavenTableRow
										loading={loading}
										onRowClick={() => {
											setIsModalOpen(true);
										}}
										one={
											<TwoDetailView
												title={
													capitalizeFirstWord(
														formatTypeFunction(chi?.merchant.name) as any
													) || '----'
												}
												text_one={chi.merchant.email}
											/>
										}
										two={
											<TwoDetailView
												title={
													capitalizeFirstWord(
														formatTypeFunction(
															chi?.bankDetails.account_name
														) as any
													) || '----'
												}
												text_one={chi?.bankDetails.bank_name}
												text_two={chi?.bankDetails.account_number}
											/>
										}
										three={chi?.type || ''}
										four={chi?.amount}
										five={<DateFragmentBox date={chi?.date} />}
										six={
											<div className="status_and_modal_holder">
												<BadgeComponent
													text={(() => {
														if (String(chi?.status === '0')) {
															return 'Disabled';
														} else {
															return 'Success';
														}
													})()}
													className={(() => {
														if (String(chi?.status === '1')) {
															return 'failed';
														} else {
															return 'success';
														}
													})()}
												/>
												<div onClick={(e) => {}}>
													{/* <DropMore
                        idx={idx}
                        className={isDropped(idx) && 'show-drop'}
                        chi={chi}
                      /> */}

													{icons.three_dots}
												</div>
											</div>
										}
									/>
								))}
							</RavenTable>
						</div>
						{/* table content end */}
					</div>
				</PageLayout>
			</DashboardLayout>

			<RavenModal
				onClose={closeModal}
				visble={isModalOpen}
				btnLabel="Create Pin"
				btnColor="green-light"
				
				onBtnClick={function (e?: any | undefined): void {
					throw new Error('Function not implemented.');
				}}
			>
			<SettlementModal
			{...settlementDetails}
			/>
			</RavenModal>
			<EditFeeCategoryModal
			// onClose={() => {
			// 	setShowModal((prev) => {
			// 		return { ...prev, edit_fee: false };
			// 	});
			// }}
			// visible={showModal?.edit_fee}
			// onFinish={handleFinish}
			// detail={singleChild}
			/>
		</>
	);
};
export default Settlement;
